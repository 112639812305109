import {
  isSubscriptionIncomplete,
  isSubscriptionIncompleteExpired,
} from "@/react/helpers/AccountSubscriptionHelpers";
import { isPromoCodeEnabled } from "@/react/helpers/communityHelpers";
import {
  isAnnual,
  isPlanBasic,
  isPlanBusiness,
  isPlanEnterprise,
  isPlanPlus,
  isPlanProfessional,
  isUpgrading,
} from "@/react/helpers/planHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsMarketingHubStatusEnabled } from "./useIsMarketingHubStatus";

export const usePlanStatus = () => {
  const { currentCommunity = {} } = usePunditUserContext();
  const {
    is_on_trial,
    default_currency: defaultCurrency,
    is_expired_trial,
    can_choose_currency: canChooseCurrency,
    current_plan_tier: currentPlanTier,
    current_plan_interval: currentPlanInterval,
    has_addons: hasAddons,
    has_active_offer: hasActiveOffer,
    current_plan: currentPlan,
  } = currentCommunity;

  const isMarketingHubStatusEnabled = useIsMarketingHubStatusEnabled();
  const isCommunityOnTrial = Boolean(is_on_trial);
  const isCommunityOnPlus = isPlanPlus(currentPlanTier);
  const isCommunityOnEnterprise = isPlanEnterprise(currentPlanTier);
  const isCommunityOnProfessional = isPlanProfessional(currentPlanTier);
  const isCommunityOnBasic = isPlanBasic(currentPlanTier);
  const isCommunityOnBusiness = isPlanBusiness(currentPlanTier);
  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);
  const isExpiredTrial = is_expired_trial;
  const isCommunitySubscriptionIncompleteExpired =
    isSubscriptionIncompleteExpired(currentCommunity.subscription?.status);
  const isCommunitySubscriptionIncomplete = isSubscriptionIncomplete(
    currentCommunity.subscription?.status,
  );
  const isCommunitySubscriptionFullyCancelled =
    currentCommunity?.subscription?.is_cancelled &&
    !currentCommunity?.subscription?.on_grace_period;
  const isCommunitySubscriptionOnGracePeriod =
    currentCommunity?.subscription?.on_grace_period;
  // past_due or incomplete
  const hasCommunityIncompletePayment =
    currentCommunity?.subscription?.has_incomplete_payment;

  const changePlanModalParams = minimumRequiredTier => {
    // Note: for later, we should allow upgrades between the same tier, as some legacy plans
    // doesn't have access to new features already available in the new version of tiers
    const upgrading = isUpgrading(currentPlanTier, minimumRequiredTier);

    if (isCommunityOnTrial) {
      if (currentPlan.public) {
        return upgrading
          ? { planTier: minimumRequiredTier }
          : { planTier: currentPlanTier };
      }

      return upgrading
        ? { planTier: minimumRequiredTier }
        : { planId: currentPlan.id };
    }

    return { planTier: minimumRequiredTier };
  };

  const shouldCollectTaxId = plan =>
    plan.currency === "brl" && isCommunityOnTrial && !isExpiredTrial;

  const shouldShowProration = newPlan => {
    if (isMarketingHubStatusEnabled) {
      return false;
    }

    if (!newPlan) {
      return false;
    }

    if (isCommunityOnTrial || isExpiredTrial) {
      return false;
    }

    if (currentPlanInterval != newPlan.interval) {
      return true;
    }

    if (isAnnual(currentPlanInterval) && isAnnual(newPlan.interval)) {
      return true;
    }

    return hasAddons;
  };

  return {
    isCommunityOnTrial,
    isCommunityOnEnterprise,
    isCommunityOnProfessional,
    isCommunityOnBasic,
    isCommunityOnPlus,
    isCommunityOnBusiness,
    currentPlanTier,
    hasActiveOffer,
    shouldCollectTaxId,
    defaultCurrency,
    canChooseCurrency,
    isExpiredTrial,
    shouldShowProration,
    isCommunitySubscriptionIncompleteExpired,
    isCommunitySubscriptionIncomplete,
    isCommunitySubscriptionFullyCancelled,
    isCommunitySubscriptionOnGracePeriod,
    hasCommunityIncompletePayment,
    isPromoCodeEnabledForCommunity,
    changePlanModalParams,
  };
};
