import { isString } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { PROFILE_INFO_QUERY_KEY } from "@circle-react/hooks/profile/useProfileInfo";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ARCHIVED_PROFILE_FIELDS_LIST_KEY } from "./useArchivedFields";
import { PAGE_PROFILE_FIELD_KEY } from "./usePageProfileFields";
import { PROFILE_FIELD_LIST, processBackendResponse } from "./useProfileFields";

export const useArchivedFieldsMutation = () => {
  const queryClient = useQueryClient();
  const toastr = useToast();

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ARCHIVED_PROFILE_FIELDS_LIST_KEY,
    });
    queryClient.invalidateQueries({ queryKey: PAGE_PROFILE_FIELD_KEY });
    queryClient.invalidateQueries({ queryKey: PROFILE_INFO_QUERY_KEY });
    queryClient.invalidateQueries({ queryKey: PROFILE_FIELD_LIST });
  };

  const handleToastError = err => {
    const { errorDetails, message } = err;
    if (isString(errorDetails)) {
      toastr.error(errorDetails);
      return;
    }
    toastr.error(message ?? t("something_went_wrong"));
  };

  const getFilteredFields = (fields, id) => {
    const filteredList = fields.filter(({ id: fieldId }) => fieldId !== id);
    return processBackendResponse(filteredList);
  };

  const archiveFieldMutations = useMutation(
    ({ id }) => reactQueryPost(internalApi.profileFields.archive(id)),
    {
      onMutate: async ({ id }) => {
        await queryClient.cancelQueries({ queryKey: PROFILE_FIELD_LIST });

        const previousFields = queryClient.getQueryData(PROFILE_FIELD_LIST);
        queryClient.setQueryData(PROFILE_FIELD_LIST, oldFields =>
          getFilteredFields(oldFields, id),
        );
        return { previousFields };
      },
      onError: (err, _, context) => {
        handleToastError(err);
        queryClient.setQueryData(
          PROFILE_FIELD_LIST,
          processBackendResponse(context.previousFields),
        );
      },
      onSuccess: handleMutationSuccess,
    },
  );

  const unarchiveFieldMutations = useMutation(
    ({ id }) => reactQueryPost(internalApi.profileFields.unarchive(id)),
    {
      onMutate: async ({ id }) => {
        await queryClient.cancelQueries({
          queryKey: ARCHIVED_PROFILE_FIELDS_LIST_KEY,
        });

        const previousFields = queryClient.getQueryData(
          ARCHIVED_PROFILE_FIELDS_LIST_KEY,
        );
        queryClient.setQueryData(ARCHIVED_PROFILE_FIELDS_LIST_KEY, oldFields =>
          getFilteredFields(oldFields, id),
        );
        return { previousFields };
      },
      onError: (err, _, context) => {
        handleToastError(err);
        queryClient.setQueryData(
          ARCHIVED_PROFILE_FIELDS_LIST_KEY,
          processBackendResponse(context.previousFields),
        );
      },
      onSuccess: handleMutationSuccess,
    },
  );

  return {
    archiveFieldMutations,
    unarchiveFieldMutations,
  };
};
