export const planTiers = Object.freeze({
  basic: "basic",
  professional: "professional",
  enterprise: "enterprise",
  plus: "plus",
  business: "business",
});

export const tiersHierarchy: any = Object.freeze({
  basic: 1,
  professional: 2,
  business: 3,
  enterprise: 4,
  plus: 5,
});

export const planIntervals = Object.freeze({
  month: "month",
  year: "year",
  quarter: "quarter",
  biannual: "biannual",
});

export const isAnnual = (interval: any) => interval === planIntervals.year;
export const isMonthly = (interval: any) => interval === planIntervals.month;

export const isPlanBasic = (tier: any) => tier === planTiers.basic;
export const isPlanProfessional = (tier: any) =>
  tier === planTiers.professional;
export const isPlanEnterprise = (tier: any) => tier === planTiers.enterprise;
export const isPlanPlus = (tier: any) => tier === planTiers.plus;
export const isPlanBusiness = (tier: any) => tier === planTiers.business;

export const isDowngrading = (currentTier: any, newTier: any) =>
  tiersHierarchy[newTier] < tiersHierarchy[currentTier];

export const isUpgrading = (currentTier: any, newTier: any) =>
  tiersHierarchy[newTier] > tiersHierarchy[currentTier];

export const getNextTier = (currentTier: any) => {
  const allTierKeys = Object.keys(tiersHierarchy);
  const currentTierIndex = allTierKeys.indexOf(currentTier);
  const isNotLastTier = currentTierIndex < allTierKeys.length - 1;
  const nextTier = allTierKeys[currentTierIndex + 1];

  if (isNotLastTier) {
    return nextTier;
  }

  // if current tier is the highest
  return null;
};

export const nextPlanTierForWorkflowUpgrade = (tier: any) => {
  if (isPlanBasic(tier) || isPlanProfessional(tier)) {
    return planTiers.business;
  }
  return planTiers.enterprise;
};
